import React, {useEffect, useRef, useState} from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import axios from 'axios';
import ReactTimeAgo from "react-time-ago";
// @ts-ignore
import Image from "react-graceful-image";
import {Select, Card, Typography, List, Row, Col, Spin, Modal} from 'antd';

const {Option} = Select;
const {Title} = Typography;
import styles from './WebSocketComponent.module.css';
import moment from "moment";

type Props = {
    locations: string[];
}

const WebSocketComponent: React.FC<Props> = ({locations}) => {
    const [locationCode, setLocationCode] = useState(locations[0] || '');
    const [image, setImage] = useState<any>(null);
    const [text, setText] = useState<string>('CLEAR');
    const [eventTime, setEventTime] = useState<any>(null);
    const [events, setEvents] = useState<any>([]);
    const [blink, setBlink] = useState(false);
    const [groupedEvents, setGroupedEvents] = useState<any>({});
    const [maxDuration, setMaxDuration] = useState<any>(null);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<any>(false);
    const [selectedSession, setSelectedSession] = useState<any>(null);

    const rwsRef = useRef<ReconnectingWebSocket | null>(null);
    const lastMessageTimeRef = useRef<Date>(new Date());

    const backendPath = 'https://api.yewfi.com'
    // const backendPath = 'http://localhost:5000'

    const statusColors = {
        'PAID': 'green',
        'ENROLLED': 'orange',
        '-': 'black',
        'BAD': 'red'
    }

    useEffect(() => {

        if (rwsRef.current) {
            rwsRef.current.close();
            rwsRef.current = null;
        }

        fetchLatestEvents();

        const rws = new ReconnectingWebSocket(`wss://as4xm6won4.execute-api.us-west-2.amazonaws.com/production?location_code=${locationCode}`);
        rwsRef.current = rws;

        rws.addEventListener('message', (message) => {
            const data = JSON.parse(message.data);
            processEvent(data)
        });

        // create an interval that checks the last message time and refreshes connection if needed
        const checkInterval = setInterval(() => {
            const now = new Date();
            const diffInMinutes = (now.getTime() - lastMessageTimeRef.current.getTime()) / (1000 * 60);

            if (diffInMinutes >= 9) { // adjust time as needed
                if (rwsRef.current) {
                    rwsRef.current.reconnect(1000, 'Manual reconnect');
                    lastMessageTimeRef.current = new Date();
                }
            }
        }, 1000 * 60); // check every minute

        // return a cleanup function to clear interval and close connection when component unmounts or locationCode changes
        return () => {
            clearInterval(checkInterval);
            if (rwsRef.current) {
                rwsRef.current.close();
            }
        };


    }, [locationCode]);

    useEffect(() => {
        if (image) {
            setBlink(true);
            const timer = setTimeout(() => setBlink(false), 3000); // blink for 2 seconds
            return () => clearTimeout(timer);
        }
    }, [image]);

    // group events on component render or update
    useEffect(() => {
        const groups = events.reduce((acc: any, event: any) => {
            (acc[event.session_id] = acc[event.session_id] || []).push(event);
            return acc;
        }, {});
        const filteredGroups = Object.keys(groups).reduce((acc: any, sessionId: any) => {
            const group = groups[sessionId];
            if (group.some((event: any) => event.event_type === 'PAYMENT_ENTER')) {
                acc[sessionId] = group;
            }
            return acc;
        }, {});

        let maxDurationSeconds = 0;

        // Iterating over the filteredGroups to calculate maxDuration
        Object.values(filteredGroups).forEach((group: any) => {
            const enterEvent = group.find((e: any) => e.event_type === 'PAYMENT_ENTER');
            const exitEvent = group.find((e: any) => e.event_type === 'PAYMENT_EXIT');
            if (enterEvent && exitEvent) {
                const enterTs = moment(enterEvent.event_ts);
                const exitTs = moment(exitEvent.event_ts);
                const duration = moment.duration(exitTs.diff(enterTs)).asSeconds();
                if (duration > maxDurationSeconds) {
                    maxDurationSeconds = duration;
                }
            }
        });
        setMaxDuration(maxDurationSeconds);
        setGroupedEvents(filteredGroups);
    }, [events]);

    const fetchLatestEvents = async () => {
        try {
            const response = await axios.get(`${backendPath}/event/${locationCode}/last_sessions/20`);
            const data = response.data;
            processEvents(data)
        } catch (err) {
            console.error(err);
        }
    };

    const processEvent = async (data: any) => {
        if (data.event_type === 'PAYMENT_ENTER') {
            setImage(data.meta?.vehicle_image);
            setText(data.event_value);
        } else if (data.event_type === 'PAYMENT_EXIT') {
            setImage(null);
            setText('CLEAR');
        }
        setEventTime(new Date(data.event_ts));
        lastMessageTimeRef.current = new Date(); // update last message time

        setEvents((prevEvents: any[]) => {
            // Get the last 10 events
            // const newEvents = [...prevEvents, {
            //     event_type: data.event_type,
            //     event_value: data.event_value,
            //     event_ts: data.event_ts
            // }]
            //     .sort((a, b) => new Date(b.event_ts).getTime() - new Date(a.event_ts).getTime())
            //     .slice(0, 10);

            // Add the new event
            const newEvents = [...prevEvents, data].sort((a, b) => new Date(b.event_ts).getTime() - new Date(a.event_ts).getTime());

            // Group events by session_id
            const groupedEvents = newEvents.reduce((grouped: any, event: any) => {
                if (!grouped[event.session_id]) {
                    grouped[event.session_id] = [];
                }
                grouped[event.session_id].push(event);
                return grouped;
            }, {});

            // Get the session_ids of the 10 most recent sessions
            const recentSessionIds = Object.keys(groupedEvents)
                .sort((a: any, b: any) => new Date(groupedEvents[b][0].event_ts).getTime() - new Date(groupedEvents[a][0].event_ts).getTime())
                .slice(0, 10);

            // Filter the events to only include events from the 10 most recent sessions
            const recentEvents = newEvents.filter(event => recentSessionIds.includes(event.session_id));


            return recentEvents;
        });
    }

    const processEvents = async (data: any) => {
        const last = data[0]
        if (last.event_type === 'PAYMENT_ENTER') {
            setImage(last.meta?.vehicle_image);
            setText(last.event_value);
        } else if (last.event_type === 'PAYMENT_EXIT') {
            setImage(null);
            setText('CLEAR');
        }
        setEventTime(new Date(last?.event_ts));
        lastMessageTimeRef.current = new Date(); // update last message time

        setEvents(data);
    }

    const showSessionInfo = (e: any, sessionEvents: any) => {
        e.preventDefault();
        setSelectedSession(sessionEvents);
        setIsDetailsModalOpen(true);
    }

    const handleDetailsModalClose = () => {
        setIsDetailsModalOpen(false);
        setSelectedSession(null);
    }

    const showSession = (sessionEvents: any) => {
        const enterEvent = sessionEvents.find((e: any) => e.event_type === 'PAYMENT_ENTER');
        if (enterEvent) {
            setImage(enterEvent?.meta?.vehicle_image);
            setText(enterEvent.event_value);
            setEventTime(new Date(enterEvent?.event_ts))
        }
    }


    return (
        <div style={{height: '100%', maxHeight: '100%', width: '95%', display: 'flex', flexDirection: 'column'}}>
            <Row style={{
                paddingTop: '20px',
                paddingBottom: '10px'
            }}>
                {locations.length > 1 &&
                    <Select
                        defaultValue={locationCode}
                        style={{width: '100%'}}
                        onChange={(value: any) => setLocationCode(value)}
                    >
                        {
                            locations.map((location) =>
                                <Option value={location} key={location}>{location}</Option>
                            )
                        }
                    </Select>
                }
            </Row>
            <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Row style={{
                    flex: '0 0 calc(50% - (50px/2))',
                    overflow: 'auto',
                    paddingTop: '10px',
                    paddingBottom: '10px'
                }}>
                    <div style={{display: 'grid', placeItems: 'center', width: '100%'}}>
                        {image
                            ? <div className={`${styles.imageContainer} ${blink ? styles.blink : ''}`}
                                   style={{
                                       position: 'relative',
                                       display: 'flex',
                                       width: 'calc(100% - 20px)',
                                       alignItems: 'center',
                                       justifyContent: 'center',
                                       alignContent: 'center'
                                   }}>
                                <Image src={image}
                                       alt='Vehicle'
                                       style={{maxWidth: '100%', width: '100%', height: 'auto'}}
                                />
                                <div style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 5,
                                    color: 'red',
                                    fontSize: '32px',
                                    fontWeight: 'bold',
                                    textShadow: '2px 2px 0 #ffffff, -2px -2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff'
                                }}>
                                    {text}
                                </div>
                            </div>
                            : <div style={{
                                width: 'calc(100% - 20px)',
                                height: 'calc(100% - 20px)',
                                backgroundColor: 'grey',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '10px solid white'
                            }}>
                                <h1 style={{color: 'white', fontWeight: 'bold'}}>CLEAR</h1>
                            </div>
                        }
                    </div>
                </Row>
                <Row style={{
                    flex: '0 0 calc(50% - (50px/2))',
                    overflow: 'auto',
                    paddingTop: '10px',
                    paddingBottom: '20px'
                }}>
                    <Card style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
                        <List
                            dataSource={Object.values(groupedEvents)}
                            renderItem={(sessionEvents: any, index: number) => {
                                const enterEvent = sessionEvents.find((e: any) => e.event_type === 'PAYMENT_ENTER');
                                const exitEvent = sessionEvents.find((e: any) => e.event_type === 'PAYMENT_EXIT');
                                const enterTs = enterEvent ? moment(enterEvent.event_ts) : null;
                                const exitTs = exitEvent ? moment(exitEvent.event_ts) : null;
                                const duration = exitTs ? moment.duration(exitTs.diff(enterTs)) : null;
                                // Calculate the duration percentage with respect to the max duration
                                // const durationPercentage = duration && maxDuration ? (duration.asSeconds() / maxDuration) * 100 : null;
                                // const durationPercentageString = durationPercentage ? `${durationPercentage}%` : '0%';
                                // const transparentPercentageString = durationPercentage ? `${100 - durationPercentage}%` : '100%';
                                const status = exitEvent?.meta ? (exitEvent?.meta?.payment?.payment_status == 'succeeded' ? 'PAID' : (exitEvent?.meta?.user?.payment_methods_available ? 'ENROLLED' : (!enterEvent?.meta?.eligible ? 'BAD' : '-'))) : (enterEvent?.meta?.user?.payment_methods_available ? 'ENROLLED' : (!enterEvent?.meta?.eligible ? 'BAD' : '-'))
                                const statusPlus = status + (enterEvent?.meta?.promo ? '*' : '')
                                const statusFontColor: string = statusColors[status]
                                const numVisits = enterEvent?.meta?.month_visits > 1 ? `[${enterEvent?.meta?.month_visits}]` : 'New'
                                const amountPaid = exitEvent?.meta?.payment?.amount || 0
                                const isActiveSession = !exitEvent;

                                return (
                                    <List.Item key={index} style={{width: '100%', userSelect: 'none'}}
                                               onClick={() => showSession(sessionEvents)}
                                               onContextMenu={(e) => showSessionInfo(e, sessionEvents)}
                                    >
                                        <div style={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                            width: '100%',
                                            backgroundColor: isActiveSession ?
                                                `rgba(144, 238, 144, 0.1)` :
                                                `transparent`
                                        }}>
                                            <div style={{textAlign: 'start'}}>
                                                {index === 0
                                                    ?
                                                    <div><ReactTimeAgo date={new Date(exitEvent?.event_ts || enterEvent?.event_ts)}
                                                                       locale={'en-US'}
                                                                       timeStyle={'twitter'}/> ago</div>
                                                    : new Date(enterEvent?.event_ts).toLocaleTimeString()
                                                }
                                                {duration && <div>{Math.round(duration.asSeconds())}s</div>}
                                            </div>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                                                <div>{enterEvent?.event_value}</div>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'end',
                                                paddingLeft: '20px'}}>
                                                {numVisits}
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                paddingLeft: '20px',
                                                color: statusFontColor
                                            }}>
                                                <div>{statusPlus}</div>
                                                {status == 'PAID' && <div>${(amountPaid / 100).toFixed(2)}</div>}
                                            </div>
                                        </div>
                                    </List.Item>
                                );
                            }}
                        />
                    </Card>
                </Row>
            </div>
            <Modal centered={true} title={'Session Details'} open={isDetailsModalOpen} onCancel={handleDetailsModalClose} footer={null} style={{maxWidth: '90vw'}}>
                <pre style={{maxHeight: '80vh', maxWidth: '85vw', overflow: 'auto'}}>
                    {JSON.stringify(selectedSession, null, 2)}</pre>
            </Modal>
        </div>
    );
};

export default WebSocketComponent;
